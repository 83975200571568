/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-param-reassign */
/* eslint-disable complexity */
import Components from 'formiojs/components/Components';
import { getRandomComponentId } from 'formiojs/utils/utils';
import { customComponents } from 'hes-formio-components';
import _ from 'lodash';
import File from 'src/formio/components/FileComponent/FileComponent';

const DropDownComponent = (customComponents as any).dropDownComponent;

class DropDown extends (DropDownComponent as any) {
    createComponent(
        component: any,
        options: Record<string, any>,
        data: Record<string, any>,
        before: any,
        replacedComp: any,
    ) {
        if (!component) {
            return;
        }
        options = options || this.options;
        data = data || this.data;
        options.parent = this;
        options.parentVisible = this.visible;
        options.root = options?.root || this.root || this;
        options.localRoot = this.localRoot;
        options.skipInit = true;
        if (!(options.display === 'pdf' && this.builderMode)) {
            component.id = getRandomComponentId();
        }
        if (!this.isInputComponent && this.component.shouldIncludeSubFormPath) {
            component.shouldIncludeSubFormPath = true;
        }

        Components.components.file = File;
        const comp = Components.create(component, options, data, true);

        const path = this.calculateComponentPath(comp);
        if (path) {
            comp.path = path;
        }
        comp.init();
        if (component.internal) {
            return comp;
        }

        if (before) {
            const index = _.findIndex(this.components, { id: before.id });
            if (index !== -1) {
                this.components.splice(index, 0, comp);
            } else {
                this.components.push(comp);
            }
        } else if (replacedComp) {
            const index = _.findIndex(this.components, { id: replacedComp.id });
            if (index !== -1) {
                this.components[index] = comp;
            } else {
                this.components.push(comp);
            }
        } else {
            this.components.push(comp);
        }

        return comp;
    }

    attachEventsForStartLoading = (events: { event: string }[]) => {
        if (events?.length) {
            events?.forEach((item) => {
                (this as any).on(item.event, () => {
                    this.setLoading(this.refs?.header, true);
                });
            });
        }
    };

    attachEventsForStopLoading = (events: { event: string }[]) => {
        if (events?.length) {
            events?.forEach((item) => {
                (this as any).on(item.event, () => {
                    this.setLoading(this.refs?.header, false);
                });
            });
        }
    };

    async attach(element: any) {
        const result = await super.attach(element);

        if (this.refs?.header) {
            window.addEventListener('click', this.onClickOutside);

            if (this.component.loaderOnAttach) {
                this.setLoading(this.refs?.header, true);
            }

            this.attachEventsForStartLoading(
                (this as any).component?.triggerEventsStartLoading,
            );
            this.attachEventsForStopLoading(
                (this as any).component?.triggerEventsStopLoading,
            );
        }

        return result;
    }

    destroy() {
        super.destroy();
        window.removeEventListener('click', this.onClickOutside);
    }

    onClickOutside = (event: MouseEvent) => {
        const isDropDownHeader = (event.target as HTMLElement).closest(
            `#${this.id} [ref=header]`,
        );
        const isDropDownMenu = (event.target as HTMLElement).closest(
            `#${this.id}-${this.component.key}`,
        );

        if (!isDropDownMenu && !isDropDownHeader && this.collapsed) {
            this.collapsed = false;
        }
    };

    setLoading(element: any, loading: boolean) {
        if (!element || element.loading === loading) {
            return;
        }

        element.loading = loading;
        if (!element.loader && loading) {
            element.loader = this.ce('i', {
                class: `${this.iconClass('refresh', true)} button-icon-right`,
            });
        }
        if (element.loader) {
            if (loading) {
                this.appendTo(element.loader, element);
            } else {
                this.removeChildFrom(element.loader, element);
            }
        }
    }
}

export default DropDown;
