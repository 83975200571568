/* eslint-disable no-underscore-dangle */
import moment from 'moment-with-locales-es6';

export const DEFAULT_FORMAT = '{{systemFormat}}';

export const getDateFormatForLocales = (
    isTimeEnabled = false,
): {
    formioFormat: string;
    appFormat: string;
} => {
    const defaultFormat = 'dd/MM/yyyy';
    const defaultFormat2 = 'DD/MM/yyyy';
    const browserLocale = window.navigator.language;
    const format = defaultFormat;
    const timeFormat = 'HH:mm:ss';

    const formioFormat = isTimeEnabled
        ? `${defaultFormat} ${timeFormat}`
        : format;

    const isUSALocale = browserLocale === 'en-US';

    if (isUSALocale) {
        return {
            formioFormat,
            appFormat: defaultFormat2,
        };
    }

    return {
        formioFormat,
        appFormat: defaultFormat2,
    };
};

export const getDateFormattedValue = (
    value: string,
    isTimeEnabled: boolean,
) => {
    const formattedDate = moment(value).format('DD/MM/yyyy');
    const formattedTime = moment(value).format('HH:mm:ss');
    const formattedDateDivider = formattedDate;
    const formattedValue = isTimeEnabled
        ? `${formattedDateDivider} ${formattedTime}`
        : formattedDateDivider;

    return (value ? formattedValue : value) || '';
};
