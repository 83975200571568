import { Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { EllipsisContainer } from 'src/components/EllipsisContainer/EllipsisContainer';
import { EntityStatusInfo } from 'src/components/EntityStatusInfo/EntityStatusInfo';
import {
    MainContainer,
    MainEntityListItemText,
    MasterDetailsAsideItem,
    SecondaryContainer,
    SecondaryEntityListItemText,
} from 'src/components/MasterDetailsPage/MasterDetailsAsideItem';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { getDateFormattedValue } from 'src/formio/components/Datetime/utils';
import { TransactionBrief } from 'src/generated-api-client';
import { TransactionStatusInfoMap } from 'src/pages/TransactionsPage/TransactionStatusInfoMap';
import { SettingsStore } from 'src/stores/SettingsStore/SettingsStore';
import { NumberHelper } from 'src/utils/NumberHelper';

type TransactionListItemProps = { item: TransactionBrief };

export const TransactionListItem = observer(
    ({ item }: TransactionListItemProps) => {
        const { i18n } = useTranslation();
        const currencyMnemonic = SettingsStore.currencyMnemonic as string;

        return (
            <MasterDetailsAsideItem
                to={EntityLinks.transactions.itemDetails(item.id)}
            >
                <MainContainer>
                    <Tooltip title={item.paymentType}>
                        <EllipsisContainer>
                            <MainEntityListItemText className="strong-text">{`${item.id} `}</MainEntityListItemText>
                            <MainEntityListItemText>
                                {item.paymentType}
                            </MainEntityListItemText>
                        </EllipsisContainer>
                    </Tooltip>
                    <EntityStatusInfo
                        statusInfoMap={TransactionStatusInfoMap}
                        status={item.status}
                        entityName="transaction"
                    />
                </MainContainer>
                <SecondaryContainer>
                    <SecondaryEntityListItemText>
                        <span className="strong-text">
                            {NumberHelper.currencyFormatter(
                                item.amount,
                                2,
                                currencyMnemonic,
                                i18n.language,
                            )}
                        </span>
                    </SecondaryEntityListItemText>
                    <SecondaryEntityListItemText>
                        {getDateFormattedValue(item.createdAt as string, true)}
                    </SecondaryEntityListItemText>
                </SecondaryContainer>
            </MasterDetailsAsideItem>
        );
    },
);
