/* eslint-disable no-underscore-dangle */
import Components from 'formiojs/components/Components';

const TextfieldComponent = (Components as any).components.textfield;

class TextField extends (TextfieldComponent as any) {
    get prefix() {
        const parentPrefix = super.prefix;

        return this.interpolate(parentPrefix, {
            data: this.data,
            row: this.data,
        });
    }

    get suffix() {
        const parentSuffix = super.suffix;

        return this.interpolate(parentSuffix, {
            data: this.data,
            row: this.data,
        });
    }

    setValue(value: any, flags: any = {}) {
        const res = super.setValue(value, flags);

        this.redraw();

        return res;
    }

    attach(element: any) {
        if (this?._parentDisabled || this?._disabled) {
            this.component.inputMask = '';
        }

        return super.attach(element);
    }
}

export default TextField;
