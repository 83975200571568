/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-param-reassign */
/* eslint-disable complexity */
/* eslint-disable max-lines */
import { Utils } from 'formiojs';
import { customComponents } from 'hes-formio-components';
import _ from 'lodash';

const Pagination = (customComponents as any).paginationComponent;

class PaginationComponent extends (Pagination as any) {
    static schema() {
        return Pagination.schema({
            type: 'paginationComponent',
            components: [
                {
                    label: 'Data list',
                    tableView: false,
                    key: 'dataList',
                    type: 'hidden',
                    input: true,
                },
                {
                    label: 'Paginated list',
                    tableView: false,
                    key: 'paginatedList',
                    type: 'hidden',
                    input: true,
                },
                {
                    label: 'Current page',
                    tableView: false,
                    key: 'currentPage',
                    type: 'hidden',
                    input: true,
                },
                {
                    label: 'Total elements',
                    tableView: false,
                    key: 'totalElements',
                    type: 'hidden',
                    input: true,
                },
                {
                    label: 'Items per page',
                    tableView: false,
                    key: 'itemsPerPage',
                    type: 'hidden',
                    input: true,
                },
                {
                    label: 'Total pages',
                    tableView: false,
                    key: 'totalPages',
                    type: 'hidden',
                    input: true,
                },
            ],
        });
    }

    getPagesNum() {
        const dataSourceValue = _.get(
            (this as any).data,
            (this as any).component.dataSourcePath,
        );

        const totalElementsValueData = _.get(
            (this as any).root.data,
            (this as any).component.totalElementsValuePath,
        );
        const totalElementsValueInterpolated = (this as any).interpolate(
            (this as any).component.totalElementsValuePath,
            {
                data: (this as any)?.root?.data,
                row: (this as any)?.data,
            },
        );

        const formattedTotalElementsValue = (
            this as any
        ).component.totalElementsValuePath?.includes('{')
            ? totalElementsValueInterpolated
            : totalElementsValueData;

        const totalElementsValue =
            formattedTotalElementsValue || dataSourceValue?.length;

        return Math.ceil(
            totalElementsValue / (this as any).component.itemsPerPage,
        );
    }

    getInitialPaginatedData() {
        const dataSourceValue = _.get(
            (this as any).data,
            (this as any).component.dataSourcePath,
        );

        return (this as any).component.dataSourcePath
            ? this.paginateArray(
                  dataSourceValue,
                  1,
                  (this as any).component.itemsPerPage,
              )
            : [];
    }

    setNewPageValue(nextPage: number) {
        if ((this as any).component.dataSourcePath) {
            const paginatedListComponent = (Utils as any).getComponent(
                (this as any).components,
                'paginatedList',
            );
            const dataList = _.get(
                (this as any).data,
                (this as any).component.dataSourcePath,
            );

            const paginatedList = this.paginateArray(
                dataList,
                nextPage,
                (this as any).component.itemsPerPage,
            );
            paginatedListComponent?.setValue(paginatedList);
        }
        const currentPageComponent = (Utils as any).getComponent(
            (this as any).components,
            'currentPage',
        );
        currentPageComponent?.setValue(nextPage);
    }

    attach(element: any) {
        (this as any).loadRefs(element, {
            nextPageButton: 'single',
            previousPageButton: 'single',
            sizeBtns: 'single',
            buttonPage: 'multiple',
            sizeBtn: 'multiple',
        });

        this.interval = setTimeout(() => {
            const initialValue = {
                dataList: _.get(
                    (this as any).data,
                    (this as any).component.dataSourcePath,
                ),
                paginatedList: this.getInitialPaginatedData(),
                currentPage: (this as any).component.isStartPageZero ? 0 : 1,
                itemsPerPage:
                    (this as any).getValue()?.itemsPerPage ||
                    (this as any).component.itemsPerPage ||
                    10,
                totalElements:
                    _.get(
                        (this as any).root.data,
                        (this as any).component.totalElementsValuePath,
                    ) ||
                    _.get(
                        (this as any).root.data,
                        (this as any).component.dataSourcePath,
                    )?.length,
                totalPages: this.getPagesNum(),
            };

            const pageForSetInitialValue = (this as any).component
                .isStartPageZero
                ? 1
                : 2;

            if (
                Number((this as any).getValue()?.currentPage || 0) <
                    pageForSetInitialValue &&
                !(this as any).builderMode
            ) {
                this.setValue(initialValue, {
                    noUpdateEvent: true,
                });
            }
        }, 0);

        (this as any).addEventListener(
            (this as any).refs.nextPageButton,
            'click',
            (event: any) => {
                event.preventDefault();
                (this as any).refs.nextPageButton?.setAttribute(
                    'disabled',
                    'disabled',
                );
            },
        );

        (this as any).addEventListener(
            (this as any).refs.previousPageButton,
            'click',
            (event: any) => {
                event.preventDefault();
            },
        );

        (this as any).refs.buttonPage.forEach((pageButton: any) => {
            (this as any).addEventListener(
                pageButton,
                'click',
                (event: any) => {
                    event.preventDefault();
                    const { pageNumber } = event.target.dataset;

                    this.goToPage(pageNumber);
                },
            );
        });

        (this as any).refs.sizeBtn.forEach((sizeButton: any) => {
            (this as any).addEventListener(
                sizeButton,
                'click',
                (event: any) => {
                    event.preventDefault();
                    const pageSize = event.target.dataset.itemsPerPage;

                    this.setPageSize(pageSize);
                },
            );
        });

        return super.attach(element);
    }
}

export default PaginationComponent;
